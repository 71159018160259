
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import HRMService from "@/services/hrm-service"
import DashboardPDFmake from "@/components/dashboard-pdfmake.vue"
import DatePicker from "@/components/datepicker.vue"
import Moment from "moment";

@Component({
  components: {
    Header,
    DashboardPDFmake,
    DatePicker
  },
})
export default class FinanceReports extends Vue {

  projectSaleChartOption: any = {}
  projectSaleChartOptionExpenses: any = {};
  projectPerChartOption: any = {}
  revenue_projectPerChartOption: any = {}
  monthSaleChartOption: any = {};
  monthSaleChartOptionExpenses: any = {};
  monthSaleChartOptionsalary: any = {};
  vatChartOption: any = {}
  total_sales = 0;
  total_vat = 0;
  highest_sales = 0;
  highest_sales_id = '';
  highest_sales_po: any = {};
  selected_report_date_from: any = '';
  selected_report_date_to: any = '';
  // New Data
  total_employees = 0;
  total_saudi = 0;
  total_nonSaudi = 0;
  emp_dept = 0;

  projectWiseEmpSeries: any = [];
  projectWiseEmpChartOptions: any = {};
  newEmpSeries: any = [];
  newEmpChartOptions: any = {};
  separatedSeries: any = [];
  separatedChartOptions: any = {};
  attendancePerSeries: any = [];
  attendancePerChartOptions: any = {};
  PassportExpSeries: any = [];
  PassportExpChartOptions: any = {};
  iqamaExpSeries: any = [];
  iqamaExpChartOptions: any = {};
  projectExpSeries: any = [];
  projectExpChartOptions: any = {};
  visaExpSeries: any = [];
  visaExpChartOptions: any = {};
  workPermitExpSeries: any = [];
  workPermitExpChartOptions: any = {};
  drivinglicExpSeries: any = [];
  drivinglicExpChartOptions: any = {};
  medicalExpSeries: any = [];
  medicalExpChartOptions: any = {};
  chartObj: string = "";
  mounted() {
    this.chartObj = "all";
    this.retrieve(this.chartObj);
  }

  getMonthName(monthNumber: any) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[monthNumber - 1];
  }
  saudiNonSaudiChart: any = []
  retrieve(advanceFilteredObj: any) {
    HRMService.getAllStatistics(advanceFilteredObj)
      .then((response) => {
        let master_data = response.data
        this.total_employees = master_data['total_employees'].toLocaleString()
        this.total_saudi = master_data['saudi'].toLocaleString()
        this.total_nonSaudi = master_data['non-saudi'].toLocaleString()
        let iqamaExpiryperMonth = master_data['iqama_expiry_per_month']
        let passportExpiryperMonth = master_data['passport_expiry_per_month']
        let visaExpiryperMonth = master_data['visa_expiry_per_month']
        let workPermitExpiryperMonth = master_data['work_permit_expiry_per_month']
        let drivinglicenseExpiryperMonth = master_data['drivinglicense_expiry_per_month']
        let medicalExpiryperMonth = master_data['medicalinsurance_expiry_per_month']
        let projectExpiryperMonth = master_data['project_expiry_per_month']
        let separationperMonth = master_data['separations_per_month']
        let attendanceperMonth = master_data['attendance_per_month']
        let saudiNonSaudiPercentage = master_data['percentage']
        let empDesignation = master_data['designation_result']
        let newEmployees = master_data['joined_date_per_month']

        iqamaExpiryperMonth.forEach((x: any) => { x.month = this.getMonthName(x.month) })
        let iqama_month = iqamaExpiryperMonth.map((x: any) => x.month)
        let data_iqama_monthly = iqamaExpiryperMonth.map((x: any) => x.count)

        passportExpiryperMonth.forEach((x: any) => { x.expiry_month = this.getMonthName(x.expiry_month) })
        let passport_month = passportExpiryperMonth.map((x: any) => x.expiry_month)
        let data_passport_monthly = passportExpiryperMonth.map((x: any) => x.expiry_count)

        visaExpiryperMonth.forEach((x: any) => { x.expiry_month = this.getMonthName(x.expiry_month) })
        let visa_month = visaExpiryperMonth.map((x: any) => x.expiry_month)
        let data_visa_monthly = visaExpiryperMonth.map((x: any) => x.expiry_count)

        workPermitExpiryperMonth.forEach((x: any) => { x.expiry_month = this.getMonthName(x.expiry_month) })
        let work_permit_month = workPermitExpiryperMonth.map((x: any) => x.expiry_month)
        let data_work_permit_monthly = workPermitExpiryperMonth.map((x: any) => x.expiry_count)

        drivinglicenseExpiryperMonth.forEach((x: any) => { x.expiry_month = this.getMonthName(x.expiry_month) })
        let driving_license_month = drivinglicenseExpiryperMonth.map((x: any) => x.expiry_month)
        let data_driving_license_monthly = drivinglicenseExpiryperMonth.map((x: any) => x.expiry_count)

        medicalExpiryperMonth.forEach((x: any) => { x.expiry_month = this.getMonthName(x.expiry_month) })
        let medical_month = medicalExpiryperMonth.map((x: any) => x.expiry_month)
        let data_medical_monthly = medicalExpiryperMonth.map((x: any) => x.expiry_count)

        projectExpiryperMonth.forEach((x: any) => { x.expiry_month = this.getMonthName(x.expiry_month) })
        let project_month = projectExpiryperMonth.map((x: any) => x.expiry_month)
        let data_project_monthly = projectExpiryperMonth.map((x: any) => x.project_count)

        separationperMonth.forEach((x: any) => { x.sep_month = this.getMonthName(x.sep_month) })
        let separation_month = separationperMonth.map((x: any) => x.sep_month)
        let separationData = separationperMonth.map((x: any) => x.sep_count)

        saudiNonSaudiPercentage.forEach((x: any) => { x.name })
        this.saudiNonSaudiChart = saudiNonSaudiPercentage.map((x: any) => x.value)

        attendanceperMonth.forEach((x: any) => { x.month = this.getMonthName(x.month) })
        let month = attendanceperMonth.map((x: any) => x.month)
        let present = attendanceperMonth.map((x: any) => x.present_percentage)
        let absent = attendanceperMonth.map((x: any) => x.absent_percentage)

        empDesignation.forEach((x: any) => { x.name })
        let designation = empDesignation.map((x: any) => x.name)
        let count = empDesignation.map((x: any) => x.value)

        newEmployees.forEach((x: any) => { x.month = this.getMonthName(x.month) })
        let new_emp_month = newEmployees.map((x: any) => x.month)
        let data_new_emp_monthly = (newEmployees.map((x: any) => x.join_count))


        this.iqamaExpiry(data_iqama_monthly, iqama_month)
        this.passportExpiry(data_passport_monthly, passport_month)
        this.projectExpiry(data_project_monthly, project_month)
        this.visaExpiry(data_visa_monthly, visa_month)
        this.workPermitExpiry(data_work_permit_monthly, work_permit_month)
        this.drivinglicenseExpiry(data_driving_license_monthly, driving_license_month)
        this.medicalExpiry(data_medical_monthly, medical_month)
        this.separationMonth(separationData, separation_month)
        this.attendancePerMonth(present, absent, month)
        this.newEmployeesMonth(data_new_emp_monthly, new_emp_month)
      })
      .catch((e) => {
        console.log(e);
      });
  }
  clear() {
    this.selected_report_date_from = '';
    this.selected_report_date_to = '';
  }
  print() {
    let route: any = null;
    route = this.$router.resolve({ path: this.highest_sales_id });
    window.open(route.href);
  }
  graphFilters = [
    { type: "joined_date", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "passport", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "attendance", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "separation", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "iqama", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "visa", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "work_permit", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "drivinglicense", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "medicalinsurance", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
    { type: "project", min_param_name: "start_date", max_param_name: "end_date", start: "", end: "", range: [] },
  ];

  handleFilterChange(type: string) {
    interface AdvanceObj {
      [key: string]: any;
    }
    const advanceFilteredObj: AdvanceObj = {};

    this.graphFilters.forEach((filter) => {
      if (filter.type === type) {
        if (filter.min_param_name && filter.range && filter.range[0]) {
          const date0 = new Date(filter.range[0]);
          const formattedDate0 = Moment(date0).format("DD-MM-YYYY");
          advanceFilteredObj[filter.min_param_name] =
            formattedDate0 === "Invalid date" ? "" : formattedDate0;
        }

        if (filter.max_param_name && filter.range && filter.range[1]) {
          const date1 = new Date(filter.range[1]);
          const formattedDate1 = Moment(date1).format("DD-MM-YYYY");
          advanceFilteredObj[filter.max_param_name] =
            formattedDate1 === "Invalid date" ? "" : formattedDate1;
        }

        advanceFilteredObj["type"] = filter.type;
      }
    });
    console.log(advanceFilteredObj);
    this.retrieve(advanceFilteredObj)
  }
  ProjectWiseEmployee(data: any, category: any) {
    this.projectWiseEmpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.projectWiseEmpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  newEmployeesMonth(data: any, category: any) {
    this.newEmpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.newEmpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  pieChartOptions = {
    chart: {
      width: 380,
      type: 'donut',
    },
    labels: ['Saudi', 'Non Saudi'],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark'
      }
    },
    legend: {
      formatter: function (val: any, opts: any) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex]
      }
    },
    // title: {
    //   text: 'Gradient Donut with custom Start-angle'
    // },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  }
  separationMonth(data: any, category: any) {
    this.separatedSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.separatedChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  attendancePerMonth(present: any, absent: any, category: any) {
    this.attendancePerSeries = [
      {
        name: 'Present',
        //colorByPoint: true, 
        data: present,
        color: '#1e3c79',
      },
      {
        name: 'Absent',
        //colorByPoint: true, 
        data: absent,
        color: '#007CB4',
      }
    ]
    this.attendancePerChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  passportExpiry(data: any, category: any) {
    this.PassportExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.PassportExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  iqamaExpiry(data: any, category: any) {
    this.iqamaExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.iqamaExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  projectExpiry(data: any, category: any) {
    this.projectExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.projectExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  visaExpiry(data: any, category: any) {
    this.visaExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.visaExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  workPermitExpiry(data: any, category: any) {
    this.workPermitExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.workPermitExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  drivinglicenseExpiry(data: any, category: any) {
    this.drivinglicExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.drivinglicExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
  medicalExpiry(data: any, category: any) {
    this.medicalExpSeries = [{
      name: 'Inflation',
      data: data
    }]
    this.medicalExpChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: category,
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: true,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }

      },
      // title: {
      //   text: 'Monthly Inflation in Argentina, 2002',
      //   floating: true,
      //   offsetY: 330,
      //   align: 'center',
      //   style: {
      //     color: '#444'
      //   }
      // }
    }
  }
}
