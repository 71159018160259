
import { Vue, Prop, Component } from "vue-property-decorator";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
@Component({
    components: {
        DatePicker: VueDatepickerUi,
    },
})
export default class FilterComponent extends Vue {
    @Prop({ type: Array, default: () => [] }) graphFilters!: any[];
    @Prop({ type: Array, default: () => [] }) range!: any[];
    @Prop({ type: Function, default: () => { } }) handleFilterChange!: Function;
    @Prop({ type: Function, default: () => { } }) refreshFields!: Function;
    @Prop({ default: null }) type!: string;
    get fields(): any[] {
        return this.graphFilters;
    }
    set fields(value: any[]) {
        this.$emit("update:graphFilters", value);
    }
}
